import React, { useState, useEffect } from 'react'
import { BUTTON, INPUT, LABEL, TITLELABLE, CAPTCHA } from '../utils/constants'

import CButton from '../components/widgets/CButton'
import CInput from '../components/widgets/CInput'
import CaptchaComp from '../components/widgets/CaptchaComp'

import { MDBCardTitle, MDBRow } from 'mdbreact'

import {
  minMaxLength,
  validEmail,
  validArray,
} from '../components/utils/validations';
import axios from 'axios';
import swal from 'sweetalert';

function ConferenceRegistrationForm({ confRegData }) {
  validArray(confRegData)

  const eData = { ...confRegData.nodes[0] }

  const [verify, setVerify] = useState(false)
  const [load, setLoad] = useState({ loadFunc: () => {} })

  const [registrationFormData, setRegistrationFormData] = useState({})

  const errorDefaultData = {}

  const [formErrors, setFormErrors] = useState(errorDefaultData)

  return (
    <div>
      <form className="needs-validation" onSubmit={submitHandler} noValidate>
        {' '}
        {eData.Data.map((formAttributes, attrKey) => {
          // debugger
          console.log(formAttributes)
          console.log(formAttributes.Type_id)

          console.log(formAttributes.field_type.Field_Kind)

          const fieldType = formAttributes.field_type.Field_Kind
          switch (fieldType) {
            case LABEL:
              return (
                <p {...formAttributes.Field_attributes}>
                  {' '}
                  {formAttributes.value}{' '}
                </p>
              )

            case INPUT:
              errorDefaultData[formAttributes.Field_attributes.name] = {
                msg: 'Field required',
                valid: 'false',
              }

              const inputData = {
                ...formAttributes.Field_attributes,
                onChange: handleOnChange,
                value:
                  registrationFormData[formAttributes.Field_attributes.name] ||
                  '',
                noValidate: 'noValidate',
              }

              return <CInput fieldData={inputData} formErrors={formErrors} />

            case BUTTON:
              const buttonData = {
                ...formAttributes.Field_attributes,
              }
              return <CButton fieldData={buttonData} />
            case TITLELABLE:
              return (
                <MDBCardTitle {...formAttributes.Field_attributes}>
                  <div className="header pt-3 morpheus-den-gradient">
                    <MDBRow className="d-flex justify-content-center">
                      {' '}
                      {formAttributes.image_details && (
                        <img
                          src={formAttributes.image_details.src}
                          style={{ ...formAttributes.image_details }}
                        />
                      )}{' '}
                      <h3 className="white-text mb-3 pt-3 font-weight-bold">
                        {' '}
                        {formAttributes.Value}{' '}
                      </h3>{' '}
                    </MDBRow>{' '}
                  </div>{' '}
                </MDBCardTitle>
              )

            case CAPTCHA:
              return (
                <CaptchaComp
                  captchaCallback={captchaCallback}
                  loadCallBack={loadCallBack}
                />
              )

            default:
              return ''
          }
        })}{' '}
      </form>{' '}
    </div>
  )

  function handleOnChange(e) {
    //handleChange(e, formErrors, setRegistrationFormData, setFormErrors)
    const { name, value, minLength, maxLength, type, required } = e.target
    let currentFormErrors = formErrors
    console.log(formErrors)

    const aValue = value.trim()

    if (type === 'text') {
      if (required) {
        if (!aValue || minMaxLength(aValue, minLength)) {
          currentFormErrors[name] = {
            msg: 'Should have minimum ' + minLength + ' characters',
            valid: 'true',
          }
        } else {
          delete currentFormErrors[name]
        }
      } else {
        delete currentFormErrors[name]
        currentFormErrors[name] = {
          msg: '',
          valid: 'false',
        }
      }
    } else if (type === 'email' && (!aValue || validEmail(aValue))) {
      currentFormErrors[name] = {
        msg: 'Email address is invalid',
        valid: 'true',
      }
    } else {
      delete currentFormErrors[name]
    }
    setRegistrationFormData({ ...registrationFormData, [name]: aValue })

    setFormErrors(currentFormErrors)
    console.log(currentFormErrors)
  }

  function submitHandler(e) {
    e.preventDefault()

    if (!(Object.keys(formErrors).length === 0) || !verify) {
      alert('Please fill the form')
    } else {
      callConfRegistrationApi(function(flag) {
        if (flag) {
          setRegistrationFormData({})
          setFormErrors(errorDefaultData)
        }
        captchaLoad()
      })
    }

    ///e.target.className += " was-validated";
    //handleChange(e);
  }

  function callConfRegistrationApi(callback) {
    axios({
      method: 'post',
      url: 'https://isridstrapi.com/contact-user-data',
      data: registrationFormData,
      headers: { 'Content-Type': 'application/json' },
    })
      .then(function(response) {
        if (response.status == 200) {
          swal({
            title: 'Thank you!',
            text: 'Will get back to you soon..',
            icon: 'success',
            button: 'Okey!',
          })
          callback(true)
        }
        callback(false)
      })
      .catch(function(response) {
        console.log(response)
        callback(false)
      })
  }

  function captchaLoad() {
    if (verify) {
      load.loadFunc()
      setVerify(false)
    }
  }

  function captchaCallback(flag) {
    setVerify(flag)
  }

  function loadCallBack(loadFunc) {
    setLoad(loadFunc)
  }
}

export default ConferenceRegistrationForm
