
import React, { useState, useEffect } from 'react'
import Layout from '../components/layout'
import {
  MDBContainer,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBRow,
} from 'mdbreact'

import { graphql } from 'gatsby'

import ConferenceRegistrationForm from '../components/ConferenceRegistrationForm'


function ConferenceRegistration({ data, location }) {
  // debugger

  console.log(data);
  const { allStrapiConfRegistrationForms } = data;




  return (

    <Layout location={location} >
      <div >
        <MDBContainer>
          <MDBCard className="contactCard" style={{ padding: '0px' }}>

            <MDBCardBody className="mx-4 mt-4">
              <MDBRow>
                <MDBCol md="12">
                  <ConferenceRegistrationForm confRegData={allStrapiConfRegistrationForms} />
                </MDBCol>
              </MDBRow>
            </MDBCardBody>
          </MDBCard>
        </MDBContainer>
      </div>
    </Layout>

  )

}

export default ConferenceRegistration;


export const query = graphql`
 {
    allStrapiConfRegistrationForms {
      nodes {
        Test_type
        image_test
        Data {
          Field_order
          Title
          Type_id
          Value
          image_url
          image_details {
            src
            width
            height
          }
          field_type {
                Field_Kind
            }
          Field_attributes {
            label
            minlength
            name
            required
            type
            value
            className
            color
            float
          }
        }
        Title
        strapiId
      }
    }
  }  
`